import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

class IndexPage extends React.Component {

  render() {
    return(
      <Layout>
        <SEO title="Oras ng Himala - Libreng Himala Para Sa'yo" />
        {/* <div className="home_video_section mobile_only">
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" id="bg_video_mobile">
            <source src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/mobile-home.mp4" type="video/mp4" />
          </video>
          <h2>Libreng Himala para Sa'yo at Iyong Pamilya</h2>
        </div>
        <div className="home_video_section desktop_only">
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" id="bg_video_mobile">
            <source src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/desktop-home.mp4" type="video/mp4" />
          </video>
          <h2>Libreng Himala para Sa'yo at Iyong Pamilya</h2>
        </div> */}

        <div className="zoom-invite-wrapper">
          <div>
            <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/nationwide-zoom-healing/nov-28-2021-nationwide-healing.jpg" className="main-event-image" />
          </div>
          {/* <div>
            <h1 className="zoom-title">Nationwide Zoom Healing</h1>
          </div> */}
          {/* <div className="zoom-section">
            
            <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/zoom-welcome.png" className="welcome-image" />
            <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/zoom-desktop.png" className="welcome-image-desktop" />
            
            <div className="apostle-name mobile_only">
              <div>
                <h4>Apostle Renato D. Carillo</h4>
                <p>
                  The Man of God from the Philippines
                  <br/>
                  God's Endtime Prophet and Apostle
                </p>
              </div>
            </div>
          </div> */}

        <div className="register-white">
          <div className="register-instruction">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfHBotFk8qCxS4serNjP8QTMrf1DDypE2EzeSQn_MdncJKJtw/viewform?usp=sf_link" className="btn btn-warning btn-register">
              Click Here to Register
            </a>
            <p>or Call Us now in our hotline</p>
            <a href="tel:+63288546796" className="hotline-number">
              (02) 88-546796
            </a>
          </div>
        </div>

          <div className="message">
            <p>Kaibigan,</p>
            <p>Mula sa aking puso ay inaanyayahan kita na makasama sa ating Nationwide Healing live via Zoom na gaganapin sa <b>November 28, 2021 (3pm).</b></p>
            <p>Ibig ko kayong ipanalangin para sa inyong <i>himala</i> na matagal mo nang inaantay, tulad ng kagalingan sa mga <i>sakit at karamdaman</i>, solusyon sa <i>problema</i>, takot sa <i>virus</i> at kung ano ang plano ng Diyos sa iyong buhay.</p>
            <p>Maraming himala ang gagawin ng Diyos sa araw na ito, kaya nais ko na ito ay maranasan mo at ng iyong buong pamilya.</p>
            <p>Tumawag sa ating hotline or mag message sa ating facebook page upang makuha ang ating Zoom Meeting ID. Salamat sa iyong pagsunod, pananalangin at pagsuporta sa gawain ng Diyos.</p>
            <p>
              Nagmamahal,
              <br/>
              Apostle Renato D. Carillo
            </p>
          </div>
        </div>

        {/* <div className="register-instruction">
          <a href="tel:+63288546796" className="hotline-number">
            (02) 88-546796
          </a>
        </div> */}

        <div className="register-instruction">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfHBotFk8qCxS4serNjP8QTMrf1DDypE2EzeSQn_MdncJKJtw/viewform?usp=sf_link" className="btn btn-warning btn-register">
            Click Here to Register
          </a>
          <p>or Call Us now in our hotline</p>
          <a href="tel:+63288546796" className="hotline-number">
            (02) 88-546796
          </a>
        </div>

        <div className="message live-message">
          <p>You can also watch and experience your healing thru the following:</p>
          <div className="watch-links">
            <a href="https://www.youtube.com/channel/UCG_nkfwsIhO1OiRQUGfdLyw" target="_blank">
              <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/youtube-live.png" alt="Youtube Live" />
            </a>
            <a href="https://www.facebook.com/JIOSWM" target="_blank">
              <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/fb-live.png" alt="Youtube Live" />
            </a>
            <a href="https://himala.ph" target="_blank">
              <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/oras-ng-himala-channel.png" alt="Youtube Live" />
            </a>
          </div>
        </div>

        {/* <div style={{maxWidth: '100%'}}>
          <img src="https://s3-ap-southeast-1.amazonaws.com/pblcfyls/jioswm/onh-28-anniv-cover.jpg" style={{maxWidth: '100%'}} />
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage
